/* @import './common_css/cropper.css'; */

@import './colors.css';

.plugin_container {
    padding-left: 15px;
    padding-top: 15px;
    padding-right: 15px;
    height: 95vh;
}

.ow_catalog_hmtr {
    margin-top: 0.5rem;
}

.ow_catalog_mtr {
    margin-top: 1rem;
}

.ow_catalog_atr {
    text-align: right;
}

.ow_catalog_coi {
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--busy--color);
    float: right;
}

.ow_catalog_dds {
    border-bottom: 1px solid #007bff;
}

input[type="color"] {
    width: 3rem !important;
}